<template>
  <el-dialog
      :title="'分配电脑权限('+tenantInfo.tenantName+')'"
      width="40%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"

      @close="close"
      center>
      <el-row>
        <el-tabs v-model="activeName" type="card">
          <el-tab-pane label="页面权限" name="page">
            <div style="max-height:40vh;overflow: auto;min-height: 15vh">
              <el-tree
                  :data="moduleTree"
                  show-checkbox
                  node-key="id"
                  ref="moduleTree"
                  :check-strictly="false"
                  :default-checked-keys="defaultArr"
                  :props="defaultProps">
              </el-tree>
            </div>
          </el-tab-pane>
          <el-tab-pane label="按钮权限" name="button">
            <div style="max-height:40vh;overflow: auto;min-height: 15vh">
              <el-tree
                  :data="buttonTree"
                  show-checkbox
                  node-key="id"
                  ref="buttonTree"
                  :check-strictly="false"
                  :default-checked-keys="buttonArray"
                  :props="buttonProps">
              </el-tree>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-row>
      <el-row style="text-align: center;margin-top: 5%">
        <el-col :span="7">
          <el-button type="success" size="mini" @click="checkTenantModule">保存</el-button>

        </el-col>
        <el-col :span="7">
          <el-button type="warning" size="mini" @click="close">取消</el-button>
        </el-col>
      </el-row>
  </el-dialog>

</template>

<script>
export default {
  name: "tenant-permission",
  data() {
    return {
      tenant: this.tenantInfo.tenantCrop,
      moduleTree:[],
      defaultArr:[],
      defaultProps: {
        children: 'child',
        label: 'title',
      },
      dialogState: this.state,
      activeName: "page",
      buttonTree: [],
      buttonProps: {
        label: "buttonName"
      },
      buttonArray: [],
    }
  },
  props: {
    tenantInfo: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    state: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  created() {
    this.queryAllModuleTree();
    this.queryAllButton();
  },
  methods: {
    checkTenantModule:function (){
      let value = "是否更新权限?:" + this.tenantInfo.tenantName
      this.$confirm(value, '更新:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.saveTenantModule();
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //添加用户
    saveTenantModule: function () {
        //获取全部树中的虚拟节点
        let fakeButtonIds=this.buttonTree.map(k=>k.id)
        //选中的数组
        let chooseButtonArr=this.$refs.buttonTree.getCheckedKeys();
        //通过循环删除假节点id
        let finalButtonIds = chooseButtonArr.filter(function(value) {
            return fakeButtonIds.indexOf(value) === -1;
        });
      this.$axios({
        method: "POST",
        url: "/tenant/saveTenantModule",
        data: {
          tenant: this.tenantInfo.tenantCrop,
          moduleIds: this.$refs.moduleTree.getCheckedKeys().concat(this.$refs.moduleTree.getHalfCheckedKeys()),
          buttonIds: finalButtonIds,
          type: 1,
        }
      }).then(response => {
        if (response.data.code === 200) {
          this.$message.success("更新成功!")
          let self = this;
          setTimeout(function () {
            self.close()
          }, 1500)
        } else {
          this.$message.error(response.data.msg)
        }
      });
    },
    //查询所有模块
    queryAllModuleTree() {
      this.$axios({
        method:"GET",
        url:"/module/queryModuleTree",
        params:{
          tenantCrop:""
        }
      }).then(response=>{
        this.moduleTree=response.data.data
        this.queryTenantModuleIds()
      })
    },
    //查询该公司的moduleId
    queryTenantModuleIds(){
      this.$axios({
        method:"GET",
        url:"/tenantModule/queryModuleIdsByTenantCrop",
        params:{
          tenantCrop:this.tenantInfo.tenantCrop
        }
      }).then(response=>{
        this.moduleTree.forEach(k=>{
          k.child.forEach(v=>{
            let indexOf1=response.data.data.indexOf(k.id)
            //如果不包含二级节点 则删除一级节点
            if (!response.data.data.includes(v.id)){
              if (indexOf1!==-1)
                response.data.data.splice(indexOf1,1)
            }
            v.child.forEach(s=>{
              let indexOf=response.data.data.indexOf(v.id)
              //如果不包含三级节点 则删除二级节点,当二级节点被删除时,一级节点自动变成半选中
              if (!response.data.data.includes(s.id)){
                if (indexOf!==-1)
                response.data.data.splice(indexOf,1)
              }
            })
          })
        })
        this.defaultArr=response.data.data
      })
    },
    close: function () {
      this.$emit("close", false);
    },
    queryAllButton() {
      this.$axios({
        method: "GET",
        url: "/button/queryAllButtonTree",
        params: {
          type: 1,
        }
      }).then(response=>{
        this.buttonTree = response.data.data
        this.queryTenantButtonIds()
      })
    },
    queryTenantButtonIds() {
      this.$axios({
        method: "GET",
        url: "/button/queryTenantButtonIds",
        params: {
          tenantCrop:this.tenantInfo.tenantCrop,
          type: 1
        }
      }).then(response=>{
        this.buttonArray = response.data.data
      })
    },
  }
}
</script>

<style scoped>

</style>